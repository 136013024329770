<template>
  <b-container>
    <h1>Role Assignment</h1>
    <b-row>
      <b-col cols="6">
        <admin-role-card />
      </b-col>
      <b-col cols="6">
        <user-role-card />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import AdminRoleCard from '@/components/RoleAssignment/Admin';
import UserRoleCard from '@/components/RoleAssignment/User';

export default {
  name: `RoleAssignment`,
  components: {
    AdminRoleCard,
    UserRoleCard,
  },
};
</script>
